var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Rev0xForm'),_c('v-container',{staticClass:"pt-0 px-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"mb-6",attrs:{"dark":_vm.darkmode}},[_c('div',{staticClass:"card-header-padding card-border-bottom"},[_c('p',{staticClass:"font-weight-600 text-h3 mb-0",class:{ 'text-muted': !_vm.darkmode }},[_vm._v(" Rev0x ")])]),(_vm.approvalsSortedByAmount.length)?_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-expansion-panels',{attrs:{"accordion":"","hover":"","multiple":""}},_vm._l((_vm.approvalsSortedByAmount),function(approval,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(approval.tokenData.tkn0s)+_vm._s(approval.tokenData.tkn1s ? "/" + approval.tokenData.tkn1s : "")+" ("+_vm._s(_vm.$t(approval.network))+")"+_vm._s(approval.balance ? " - " + approval.balance : "")+" ")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":approval.contractsArr,"hide-default-footer":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.contractId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("shortenContract")(item.friendlyName || item.contractId))+" "),(approval.network === 'bsc')?_c('v-btn',{attrs:{"href":'https://bscscan.com/address/' + item.contractId,"target":"_blank","icon":"","color":"indigo lighten-1"}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-external-link-alt")])],1):(approval.network === 'matic')?_c('v-btn',{attrs:{"href":'https://polygonscan.com/address/' + item.contractId,"target":"_blank","icon":"","color":"indigo lighten-1"}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-external-link-alt")])],1):(approval.network === 'ftm')?_c('v-btn',{attrs:{"href":'https://ftmscan.com/address/' + item.contractId,"target":"_blank","icon":"","color":"indigo lighten-1"}},[_c('v-icon',{attrs:{"size":"14"}},[_vm._v("fas fa-external-link-alt")])],1):_vm._e()]}},{key:"item.management",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","color":_vm.searchedWallet === _vm.connectedWallet
                                ? 'indigo lighten-1'
                                : ''}},[_c('v-icon',_vm._g(_vm._b({staticClass:"me-2 cursor-pointer",attrs:{"size":"14"},on:{"click":function($event){return _vm.revokePermissions({
                                  token: approval.tokenID,
                                  spender: item.contractId,
                                })}}},'v-icon',attrs,false),on),[_vm._v(" fas fa-ban ")])],1)]}}],null,true)},[_c('span',[_vm._v("Revoke Permissions")])])]}}],null,true)})],1)],1)}),1)],1):_c('v-card-text',{staticClass:"text-center"},[(_vm.loading)?_c('p',[_vm._v("Loading...")]):_c('p',[_vm._v("No data available...")])]),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","opacity":1}})],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }